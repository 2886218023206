export const lenderActions = {
  GET_COMMERCES: 'GET_COMMERCES',
  GET_CATEGORIES: 'GET_CATEGORIES',
  GET_COMMISSIONS_LIST: 'GET_COMMISSIONS_LIST',
  GET_ALL_POLICIES: 'GET_ALL_POLICIES',
  SET_CURRENT_POLICY: 'SET_CURRENT_POLICY',
  SET_POLICY_COLLATERAL_STATUS: 'SET_POLICY_COLLATERAL_STATUS',
  SET_POLICY_CURRENT_COLLATERAL: 'SET_POLICY_CURRENT_COLLATERAL',
  SET_POLICY_CURRENT_STEP: 'SET_POLICY_CURRENT_STEP',
  GET_ALL_COMMISSIONS: 'GET_ALL_COMMISSIONS',
  GET_ALL_FINES: 'GET_ALL_FINES',
  GET_REGIONS: 'GET_REGIONS',

  GET_USERS: 'GET_USERS',
  GET_BRANCHES: 'GET_BRANCHES',
  GET_PERMISSIONS: 'GET_PERMISSIONS',
  GET_ROLES: 'GET_ROLES',
  GET_USER_PERMISSIONS: 'GET_USER_PERMISSIONS',

  GET_QUESTIONS: 'GET_QUESTIONS',
  GET_QUESTION_TEMPLATES: 'GET_QUESTION_TEMPLATES',
  GET_SCORING_MODELS: 'GET_SCORING_MODELS',
  SET_SCORECARD_RISK_SEGMENTS: 'SET_SCORECARD_RISK_SEGMENTS',
  SET_CURRENT_SCORECARD_RISK_SEGMENT: 'SET_CURRENT_SCORECARD_RISK_SEGMENT',
  UPDATE_SCORECARD_RISK_SEGMENT: 'UPDATE_SCORECARD_RISK_SEGMENT',
  DELETE_SCORECARD_RISK_SEGMENT: 'DELETE_SCORECARD_RISK_SEGMENT',
  ADD_SCORECARD_RISK_SEGMENT: 'ADD_SCORECARD_RISK_SEGMENT',
  SET_CURRENT_RISK_SEGMENT_ERRORS: 'SET_CURRENT_RISK_SEGMENT_ERRORS',
  SET_RISK_SEGMENT_SUCCESS_MESSAGE: 'SET_RISK_SEGMENT_SUCCESS_MESSAGE',
  SET_SCORECARD_RISK_SEGMENT_MODAL_STATUS: 'SET_SCORECARD_RISK_SEGMENT_MODAL_STATUS',
  SET_CREATE_SCORECARD_RISK_SEGMENT_MODAL_STATUS: 'SET_CREATE_SCORECARD_RISK_SEGMENT_MODAL_STATUS',
  SET_SCORECARD_ALERT_MODAL_STATUS: 'SET_SCORECARD_ALERT_MODAL_STATUS',

  SET_INIT_FETCHING_STATUS: 'SET_INIT_FETCHING_STATUS',
  SET_FETCHING_STATUS: 'SET_FETCHING_STATUS',
  SET_APPS_FILTER_RULES: 'SET_APPS_FILTER_RULES',
  SET_APPS_SEARCH_QUERY: 'SET_APPS_SEARCH_QUERY',
  SET_LENDER_TABNAME: 'SET_LENDER_TABNAME',
  SET_APPLICATIONS_ERROR_MESSAGE: 'SET_APPLICATIONS_ERROR_MESSAGE',
  SET_LENDER_APPLICATIONS: 'SET_LENDER_APPLICATIONS',
  SET_CURRENT_APPLICATION: 'SET_CURRENT_APPLICATION',
  SET_APP_ACTIVE_TAB: 'SET_APP_ACTIVE_TAB',
  SET_APPLICATION_PAYMENTS: 'SET_APPLICATION_PAYMENTS',
  SET_APPLICATION_PAYMENTS_RSE: 'SET_APPLICATION_PAYMENTS_RSE',
  SET_APPLICATION_LOAN_HISTORY: 'SET_APPLICATION_LOAN_HISTORY',
  SET_APPLICATION_LOAN_HISTORY_SERVICE_STATUS: 'SET_APPLICATION_LOAN_HISTORY_SERVICE_STATUS',
  SET_APPLICATION_WORKFLOWS: 'SET_APPLICATION_WORKFLOWS',
  SET_APPLICATION_QUESTIONARY_DATA: 'SET_APPLICATION_QUESTIONARY_DATA',
  SET_APPLICATION_QUESTIONARY_DOCUMENTS: 'SET_APPLICATION_QUESTIONARY_DOCUMENTS',
  SET_APPLICATION_DOCUMENT_CONTRACT: 'SET_APPLICATION_DOCUMENT_CONTRACT',
  SET_APPLICATION_DOCUMENT_ADDITIONAL_CONTRACT: 'SET_APPLICATION_DOCUMENT_ADDITIONAL_CONTRACT',
  SET_APPLICATION_DOCUMENT_INVOICE: 'SET_APPLICATION_DOCUMENT_INVOICE',
  SET_APPLICATION_DOCUMENT_GUARANTOR_CONTRACT: 'SET_APPLICATION_DOCUMENT_GUARANTOR_CONTRACT',
  SET_APPLICATION_PLATON_PAYMENTS: 'SET_APPLICATION_PLATON_PAYMENTS',
  SET_APPLICATION_BANK_ID: 'SET_APPLICATION_BANK_ID',
  SET_LENDER_USERS: 'SET_LENDER_USERS',
  SET_LENDER_CATEGORIES: 'SET_LENDER_CATEGORIES',
  SET_LENDER_POLICIES: 'SET_LENDER_POLICIES',
  SET_USER_RAITING: 'SET_USER_RAITING',

  SET_NEW_POLICY_ID: 'SET_NEW_POLICY_ID',
  SET_COLLATERAL_TYPES: 'SET_COLLATERAL_TYPES',
  SET_NEW_COMMISSION: 'SET_NEW_COMMISSION',
  SET_POLICY_SHOPS: 'SET_POLICY_SHOPS',
  SET_POLICY_CATEGORIES: 'SET_POLICY_CATEGORIES',
  SET_POLICY_SCORING_MODEL: 'SET_POLICY_SCORING_MODEL',
  CLEAR_NEW_POLICY: 'CLEAR_NEW_POLICY',
  CLEAR_ALL_COMMISSIONS: 'CLEAR_ALL_COMMISSIONS',
  CLEAR_ALL_FINES: 'CLEAR_ALL_FINES',
  CLEAR_PRODUCT_FIELDS: 'CLEAR_PRODUCT_FIELDS',
  SET_RULES_LIST: 'SET_RULES_LIST',
  SET_ALLOWED_RULES: 'SET_ALLOWED_RULES',
  ADD_NEW_RULE: 'ADD_NEW_RULE',
  EDIT_RULE: 'EDIT_RULE',
  DELETE_RULE: 'DELETE_RULE',
  SET_USER_LOGS: 'SET_USER_LOGS',
  SET_ALL_USERS: 'SET_ALL_USERS',
  SET_NOTIFICATIONS: 'SET_NOTIFICATIONS',
  EDIT_NOTIFICATION: 'EDIT_NOTIFICATION',
  SET_DUE_DATE_REMINDERS: 'SET_DUE_DATE_REMINDERS',
  NEW_DUE_DATE_REMINDER: 'NEW_DUE_DATE_REMINDER',
  EDIT_DUE_DATE_REMINDER: 'EDIT_DUE_DATE_REMINDER',
  DELETE_DUE_DATE_REMINDER: 'DELETE_DUE_DATE_REMINDER',
  SET_IMPORT_EXPORT_HISTORY: 'SET_IMPORT_EXPORT_HISTORY',
  SET_IMPORT_EXPORT_TOTAL_COUNT: 'SET_IMPORT_EXPORT_TOTAL_COUNT',

  SET_PROMO_CODES: 'SET_PROMO_CODES',
  NEW_PROMO_CODE: 'NEW_PROMO_CODE',
  EDIT_PROMO_CODE: 'EDIT_PROMO_CODE',
  DELETE_PROMO_CODE: 'DELETE_PROMO_CODE',

  SET_LOYALTY_PROGRAMS: 'SET_LOYALTY_PROGRAMS',
  NEW_LOYALTY_PROGRAM: 'NEW_LOYALTY_PROGRAM',
  EDIT_LOYALTY_PROGRAM: 'EDIT_LOYALTY_PROGRAM',
  DELETE_LOYALTY_PROGRAM: 'DELETE_LOYALTY_PROGRAM',

  SET_SHOPS: 'SET_SHOPS',
  NEW_SHOP: 'NEW_SHOP',
  EDIT_SHOP: 'EDIT_SHOP',
  DELETE_SHOP: 'DELETE_SHOP',

  SET_SHOP_USERS: 'SET_SHOP_USERS',
  NEW_SHOP_USER: 'NEW_SHOP_USER',
  EDIT_SHOP_USER: 'EDIT_SHOP_USER',
  DELETE_SHOP_USER: 'DELETE_SHOP_USER',

  SET_UNDERWRITING_DEPARTMENTS: 'SET_UNDERWRITING_DEPARTMENTS',
  SET_LOAN_ROUTING_CREDIT_COMMITTEES: 'SET_LOAN_ROUTING_CREDIT_COMMITTEES',
  SET_UNDERWRITING_LOADING_STATUSES: 'SET_UNDERWRITING_LOADING_STATUSES',
  SET_UNDERWRITING_MODAL_STATUSES: 'SET_UNDERWRITING_MODAL_STATUSES',
  SET_UNDERWRITING_CURRENT_DEPARTMENT: 'SET_UNDERWRITING_CURRENT_DEPARTMENT',
  SET_LOAN_ROUTING_CURRENT_CREDIT_COMMITTEE: 'SET_LOAN_ROUTING_CURRENT_CREDIT_COMMITTEE',
  SET_UNDERWRITING_CURRENT_VERIFIER: 'SET_UNDERWRITING_CURRENT_VERIFIER',
  SET_UNDERWRITING_USERS: 'SET_UNDERWRITING_USERS',

  SET_VOTING_MODAL_VISIBLE_STATUS: 'SET_VOTING_MODAL_VISIBLE_STATUS',
  SET_VOTING_MODAL_LOAD_STATUS: 'SET_VOTING_MODAL_LOAD_STATUS',
  SET_LENDER_VOTING_ID: 'SET_LENDER_VOTING_ID',
  SET_LENDER_CURRENT_VOTING: 'SET_LENDER_CURRENT_VOTING',

  SET_SETTINGS_DISCOUNT_LIST: 'SET_SETTINGS_DISCOUNT_LIST',
  SET_SETTINGS_DISCOUNT_STATUS: 'SET_SETTINGS_DISCOUNT_STATUS',
  SET_SETTINGS_CURRENT_DISCOUNT: 'SET_SETTINGS_CURRENT_DISCOUNT',
  SET_SETTINGS_DISCOUNT_MODAL_STATUS: 'SET_SETTINGS_DISCOUNT_MODAL_STATUS',

  SET_SETTINGS_CONTRACT_REPLACEMENT_LIST: 'SET_SETTINGS_CONTRACT_REPLACEMENT_LIST',
  SET_SETTINGS_CONTRACT_TEMPLATE_LIST: 'SET_SETTINGS_CONTRACT_TEMPLATE_LIST',
  SET_SETTINGS_CONTRACT_TEMPLATES_STATUS: 'SET_SETTINGS_CONTRACT_TEMPLATES_STATUS',
  SET_SETTINGS_CURRENT_CONTRACT_TEMPLATE: 'SET_SETTINGS_CURRENT_CONTRACT_TEMPLATE',
  SET_SETTINGS_CONTRACT_TEMPLATE_MODAL_STATUS: 'SET_SETTINGS_CONTRACT_TEMPLATE_MODAL_STATUS',

  SET_LENDER_SCORING_SETTINGS: 'SET_LENDER_SCORING_SETTINGS',

  SET_COMMERCE_CATEGORIES: 'SET_COMMERCE_CATEGORIES',

  SET_LENDER_TOAST_MESSAGE: 'SET_LENDER_TOAST_MESSAGE',

  SET_PLATON_PAYMENTS: 'SET_PLATON_PAYMENTS',

  SET_IS_LOADING_IMPORT: 'SET_IS_LOADING_IMPORT'
} as const
